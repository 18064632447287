import { ProductRequest } from "@/store/products";
import { Product, ProductType } from "./product";
import { User } from "./user";

export enum OrderStatus {
  pending = "pending",
  completed = "completed",
  charged = "charged",
  fulfilled = "fulfilled",
  refunded = "refunded",
}

export enum ProStatus {
  pending = "pending",
  accepted = "accepted",
  declined = "declined",
}

export enum CallStatus {
  requested = "requested",
  scheduled = "scheduled",
  declined = "declined",
  active = "active",
  completed = "completed",
  incompleted = "incompleted",
  refunded = "refunded",
}

export interface OrderRating {
  stars: number;
  message?: string;
  hideFromProProfile: boolean;
  date: Date;
  productType: ProductType;
  orderId: string;
  artistName: string;
}

export interface Order {
  _id: string;
  stripeChargeId: string;
  productId: string;
  productType: ProductType;
  product?: Product;
  proId: string;
  pro?: User;
  callStatus?: CallStatus;
  userId: string;
  user?: User;
  status: OrderStatus;
  proStatus: ProStatus;
  dueDate: Date;
  feedbackRequest: ProductRequest;
  proSaved?: boolean;
  proMessaged?: boolean;
  rating?: OrderRating;
  price: number;

  createdAt?: Date;
}
